<template>
    <a-result status="404" title="404" sub-title="对不起，你访问的内容不存在！">
        <template #extra>
            <a-button type="primary" @click="back()">返回</a-button>
        </template>
    </a-result>
</template>

<script>
    import {useRouter} from 'vue-router'
    export default {
        name: "404",
        setup(){
            const router = useRouter();
            const back = ()=>{
                router.back(-1);
            }
            return{
                back,
            }
        }
    }
</script>

<style scoped>

</style>